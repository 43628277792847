import Button from 'atoms/button';
import Image from 'next/legacy/image';
import { Col, Container, Row } from 'react-bootstrap';

export default function CtaFold({
  ctaPosts = [],
  fullWidth = false,
  asSideItem = false,
  className,
}) {
  if (ctaPosts.length < 1) {
    return null
  }

  return (
    <>
      <div className={`cta-fold ${fullWidth ? '' : 'rounded'} ${className}`}>
        <Container>
          <Row className={`${asSideItem && 'justify-content-around'}`}>
            {ctaPosts.map(post => (
              <Col key={`default-cta-${post.id}`} className={`text-center col-12 col-sm-4 ${asSideItem && 'col-sm-8 col-lg-12 my-0'} my-4`}>
                <div className="mb-2">
                  <div className={`image-wrapper ${(post.acf.icon_size && post.acf.icon_size == 'large') && 'image-large'}`}>
                    <Image
                      src={post.acf.icon.url}
                      width={post.acf.icon_size && post.acf.icon_size == 'large' ? post.acf.icon.sizes['medium-width'] : 90}
                      height={post.acf.icon_size && post.acf.icon_size == 'large' ? post.acf.icon.sizes['medium-height'] : 90}
                      layout="responsive"
                      className="cta-image"
                      alt={post.acf.icon.alt}
                    />
                  </div>
                </div>
                <Button link={post.acf.link}>{post.acf.title}</Button>
                <p className="mt-3 text-white">{post.acf.subtitle}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <style jsx>{`
        .cta-fold {
          position: relative;
          background: transparent;
          /*background-image: linear-gradient(120deg, #705CFF 0%, #5E7AFF 100%);*/
          background-color: #16a8f4; /*transparent;*/
        }
        
        .cta-fold.rounded {
          border-radius: 20px;
        }
        
        .cta-image {
          margin: auto auto;
        }
                
        .image-wrapper {
          margin: 0 auto;
          background-color: white;
          height: 90px;
          width: 90px;
          padding: 15px;
          border-radius: 50%;
        }

        .image-large {
          height: auto;
          width: 100%;
          background: none;
        }
      `}</style>
    </>
  )
}
