import Head from "next/head";

export default function ExternalCssSheet({
  href,
  uniqueKey,
}) {
  return (
    <Head>
      <link
        key={uniqueKey}
        rel="preload"
        as="style"
        href={href}
        onLoad="this.onload=null;this.rel='stylesheet'"
      />
      <noscript>
        <link key={uniqueKey} rel="stylesheet" href={href} />
      </noscript>
    </Head>
  )
}
