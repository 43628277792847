import Button from 'atoms/button'
import HtmlText from 'atoms/html-text'

export default function HeroFold({
  title,
  subtitle,
  webinarLink,
  minHeight = 70,
  dynamicTitleFontSize = false,
}) {
  return (
    <>
      <div className="hero-fold">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center mb-0">
              <h1 className={`text-white d-flex justify-content-center mb-0 ${dynamicTitleFontSize ? 'dynamic-title-size' : ''}`}>
                <HtmlText body={title} />
              </h1>
              { subtitle && <p className="text-white mt-2">{subtitle}</p>}
              {webinarLink && <Button link={webinarLink}>
                Bekijk webinar
              </Button>}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .hero-fold {
          padding: 0;
          background-color: #16a8f4;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: bottom center;
          display: flex;
          align-items: center;
        }

        .dynamic-title-size {
          font-size: calc(100% + 2vw)
        }
      `}</style>
    </>
  )
}
