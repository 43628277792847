import ExternalCssSheet from './external-css-sheet'

export default function HtmlText({
  body,
  ...props
}) {
  return (
    <div className="html-body">
      <ExternalCssSheet uniqueKey="wp-block-css" href={`${process.env.NEXT_PUBLIC_API_URL}/wp-includes/css/dist/block-library/style.min.css`} />

      <div className="dynamic-html" dangerouslySetInnerHTML={{ __html: body }} {...props} />

      <style global jsx>{`
        .dynamic-html img, iframe {
          max-width: 100% !important;
        }

        .dynamic-html img {
          height: auto !important;
        }

        .dynamic-html .row {
          all: unset !important;
        }
      `}</style>
    </div>
  )
}
