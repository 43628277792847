import Head from 'next/head'
import { Container, Row, Col } from "react-bootstrap";
import Hero from 'components/folds/common/hero-fold'
import RouterLink from "atoms/router-link";
import CtaFold from 'components/folds/common/cta-fold';

export default function Custom404() {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <main>
        <Hero title="404 - Pagina niet gevonden"/>
        <Container>
          <Row>
            <Col className="text-center">
              <h3>Klik <RouterLink href="/">hier</RouterLink> om terug te gaan naar de homepage, of <RouterLink href="/wiki">zoek naar artikelen</RouterLink>.</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <CtaFold />
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}
